import { useCallback, useEffect, useState } from 'react';
import Button from '../../../../../components/button';
import { ImageWithFallback } from '../../../../../components/imageWithFallback';
import { AmountToggle } from '../../../../../components/product-card/components/AmountToggle';
import { placeholderImages } from '../../../../../components/product-card/placeholderImage';
import { ReactComponent as ReloadIcon } from '../../../../../icons/restart.svg';
import { ReactComponent as CheckIcon } from '../../../../../icons/greenCheckIcon.svg';
import { ChangeUnit } from '../../ChangeUnit/ChangeUnit';
import styles from '../productTable.module.sass';
import { Tooltip } from '../../../../../components/tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { downloadFile } from '../../../../../utils/request';
import { createErrorNotification } from '../../../../../utils/notifications';
import { isScreenshotsInProgress } from '../../../../../store/selectors/organization';

export const ProductOverview = ({
  onUpdateProduct,
  product,
  isProductLoading,
  onChangeAmount,
  handleReloadProduct,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);

  const getDetailedReport = useCallback(async () => {
    await downloadFile(`/api/admin/products/${product?.id}/export`, `report_${product?.name}.xlsx`).catch((err) => {
      createErrorNotification('Произошла ошибка при скачивании отчета');
    });
  }, [product?.id, product?.name]);

  const loadingPlaceholderImage = placeholderImages[product?.category] ?? '/images/placeholders/other.png';

  const productImgSrc = isLoading ? loadingPlaceholderImage : product?.pics;

  useEffect(() => {
    setIsLoading(isProductLoading);
  }, [isProductLoading]);
  return (
    <>
      <div className="tw-flex tw-flex-row tw-p-4 tw-w-[70%] tw-relative projectMobile:tw-w-full projectMobile:tw-p-3">
        <Button
          variant="text"
          onClick={handleReloadProduct}
          disabled={screenshotsIsUpdating}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[10px] tw-right-[6px] projectMobile:tw-right-[4px] projectMobile:tw-top-[42px]"
        >
          <ReloadIcon className="tw-fill-[#134B98] tw-h-[28px] tw-w-[28px]" />
        </Button>
        <ImageWithFallback
          srcImg={productImgSrc}
          fallbackSrc={'/images/placeholders/other.png'}
          className="tw-min-w-[120px] tw-min-h-[120px] tw-max-h-[120px] tw-max-w-[120px] tw-rounded-[4px] tw-aspect-square projectMobile:tw-min-w-[100px] projectMobile:tw-min-h-[100px] projectMobile:tw-max-w-[100px] projectMobile:tw-max-h-[100px]"
          loading="lazy"
          alt="product_img"
        />
        <div className="tw-flex-tw-flex-col tw-w-full tw-ml-[14px] tw-gap-3">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-max-w-[379px]">
            <Tooltip title={product?.name}>
              <p
                className={`tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold ${styles.productName} projectMobile:tw-max-w-[138px]`}
              >
                {product?.name}
              </p>
            </Tooltip>
            {product?.use_gpt && (
              <div className="tw-bg-[#EEFCEE] tw-flex tw-flex-row tw-gap-[2px] tw-items-center tw-py-1 tw-px-2 tw-rounded-3xl projectMobile:tw-hidden">
                <CheckIcon />
                <p className="tw-text-[#49CA4E] tw-text-[12px] tw-leading-4 tw-font-medium">
                  Применён оптимизатор названий
                </p>
              </div>
            )}
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mt-1 projectMobile:tw-hidden">
              <AmountToggle currentAmount={product?.amount} handleChange={onChangeAmount} />

              <ChangeUnit currentUnit={product?.unit} onChange={onUpdateProduct} />
            </div>
            {user?.super_admin && (
              <Button variant="text" className="tw-w-fit tw-p-0 tw-text-sm" onClick={getDetailedReport}>
                Скачать отчет
              </Button>
            )}
          </div>
        </div>
      </div>
      {product?.use_gpt && (
        <div className="tw-hidden projectMobile:tw-block tw-px-3 tw-max-w-[259px] tw-mb-[10px]">
          <div className="tw-bg-[#EEFCEE] tw-flex tw-flex-row tw-gap-[2px] tw-items-center tw-py-1 tw-px-2 tw-rounded-3xl">
            <CheckIcon className='tw-w-5 tw-h-5' />
            <p className="tw-text-[#49CA4E] tw-text-[12px] tw-leading-4 tw-font-medium">
              Применён оптимизатор названий
            </p>
          </div>
        </div>
      )}
      <div className="projectMobile:tw-flex tw-flex-row tw-items-center tw-gap-4 tw-hidden tw-px-3">
        <AmountToggle currentAmount={product?.amount} handleChange={onChangeAmount} />

        <ChangeUnit currentUnit={product?.unit} onChange={onUpdateProduct} />
      </div>
    </>
  );
};
