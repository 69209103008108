import { Form } from 'react-final-form';
import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { UNIT_OPTIONS } from '../../../../utils/constant';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isScreenshotsInProgress } from '../../../../store/selectors/organization';
import Button from '../../../../components/button';
import { useDialog } from '../../../../providers/dialog.provider';
import { useCallback } from 'react';
import { DropdownModal } from '../../../../components/modals/DropdownModal';

const sx = {
  '&.MuiInputBase-root': {
    '& .MuiSelect-select': {
      padding: '0px !important',
    },
    fieldset: {
      border: 'none',
    },
  },
};

export const ChangeUnit = ({ currentUnit, onChange, asTable }) => {
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);
  const isMobile = useMediaQuery('(max-width:425px');
  const dialog = useDialog();

  const handleMobileOptionClick = useCallback(
    (value) => {
      onChange(value);
      dialog.close();
    },
    [dialog, onChange]
  );

  const openMobileDropdown = useCallback(() => {
    dialog.open(
      <DropdownModal>
        <div className="tw-w-full tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-divide-y tw-divide-[#F7F7F7]">
          {UNIT_OPTIONS.map((option) => (
            <div className="tw-w-[90%]" key={option.label}>
              <Button
                onClick={() => handleMobileOptionClick({ unit: option.value })}
                className="tw-w-full"
                variant="text"
              >
                {option.label}
              </Button>
            </div>
          ))}
        </div>
      </DropdownModal>
    );
  }, [dialog, handleMobileOptionClick]);

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
      {!asTable && <p className="tw-text-[#666666] tw-text-sm tw-leading-[16px] tw-font-medium">Ед. измерения</p>}
      {!isMobile ? (
        <Form onSubmit={() => {}}>
          {({ handleSubmit }) => (
            <Select
              name="unit"
              defaultValue={currentUnit}
              className={classNames('tw-text-[#134B98] tw-text-sm tw-font-medium ', {
                'tw-underline-offset-2 tw-underline': !asTable,
              })}
              IconComponent={() => null}
              sx={sx}
              disabled={screenshotsIsUpdating}
              onChange={(e, _) =>
                onChange({
                  unit: e.target.value,
                })
              }
            >
              {UNIT_OPTIONS.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Form>
      ) : (
        <Button
          variant="text"
          onClick={openMobileDropdown}
          className="tw-w-fit tw-bg-transparent hover:tw-bg-transparent tw-p-0 tw-underline tw-font-normal tw-text-sm"
        >
          {currentUnit}
        </Button>
      )}
    </div>
  );
};
