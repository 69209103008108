import { useParams } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import { useGetProjectReportQuery, useStopSearchMutation } from '../../services';
import { ProductsList } from './components/Product/ProductsList';
import moment from 'moment';
import { AddProduct } from './components/AddProduct/index';
import { ReportStatus } from './components/ReportStatus/ReportStatus';
import { CreateProjectCard } from './components/CreateProjectCard/CreateProjectCard';
import { SidePanel } from './components/SidePanel/SidePanel';
import { useCallback, useMemo, useRef } from 'react';
import { useDialog } from '../../providers/dialog.provider';
import { CheckCorrectProductsNames } from '../products/components/CheckCorrectProductsNames';
import { RequestLimitNotification } from '../../components/request-limit';
import { createNotification } from '../../utils/notifications';
import { REPORT_STATUS } from '../../utils/constant';
import { ScreenshotsProgress } from './components/Screenshots/ScreenshotsProgress';
import { useSelector } from 'react-redux';
import { isScreenshotsInProgress } from '../../store/selectors/organization';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useOnScreen } from '../../hooks/useOnScreen';

export const Project = () => {
  const { id } = useParams();
  const dialog = useDialog();
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);
  const [stopSearch] = useStopSearchMutation();
  const laptop = useMediaQuery('(max-width: 1256px)');
  const tablet = useMediaQuery('(max-width:769px)');
  const mobile = useMediaQuery('(max-width: 523px)')

  const { data } = useGetProjectReportQuery(
    {
      project_id: id,
    },
    {
      pollingInterval: 15000,
    }
  );

  const report = data?.reports?.[0];

  const isOptimizing = report?.status === REPORT_STATUS.OPTIMIZED;

  const noProductsInProject = useMemo(() => {
    const noProducts = report?.total_count === 0;

    return report?.status === REPORT_STATUS.INITIAL && noProducts;
  }, [report?.status, report?.total_count]);

  const openOptimizingModalForm = useCallback(() => {
    dialog.open(<CheckCorrectProductsNames />);
  }, [dialog]);

  const handleStopProjectSearch = useCallback(async () => {
    await stopSearch(id)
      .unwrap()
      .then(() => {
        createNotification('Поиск проекта остановлен');
      });
  }, [id, stopSearch]);

  const sidePanelRef = useRef(null);
  const isVisible = useOnScreen(sidePanelRef);
  return (
    <>
      <GeneralLayout >
        <div className="tw-flex tw-flex-col tw-w-full tw-relative projectTablet:tw-pb-[140px] projectMobile:tw-pb-[330px]">
          {isOptimizing && openOptimizingModalForm()}
          <div className="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-mb-6 tw-w-full">
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
              <h2 className="tw-text-[#191919] tw-font-semibold tw-font-roboto tw-text-[32px] tw-leading-[38px]">
                {data?.project_name}
              </h2>
              <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">
                Дата создания {moment(report?.created_at).format('DD.MM.YYYY')}
              </p>
            </div>
            
              <RequestLimitNotification
                containerClassName={'tw-mb-6'}
                stopCallback={handleStopProjectSearch}
                isSearching={report?.status === REPORT_STATUS.PROCESSING}
              />
            
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-gap-[27px]">
            <div
              className={classNames('tw-flex tw-flex-col tw-w-full', {
                'tw-max-w-[72%]': !laptop,
              })}
            >
              <div className="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-mb-6">
                <AddProduct />
                <CreateProjectCard isEmptyProject={noProductsInProject} />
                {laptop && !noProductsInProject && (
                  <div ref={sidePanelRef}>
                    <SidePanel />
                  </div>
                )}
                <ReportStatus />
              </div>
              {!noProductsInProject && <ProductsList report_id={report?.id} />}
            </div>
            {!laptop && (
              <div className="tw-w-[28%]">
                <SidePanel isProjectEmpty={noProductsInProject} report_id={report?.id} />
              </div>
            )}
          </div>
          {screenshotsIsUpdating && <ScreenshotsProgress />}
        </div>
      </GeneralLayout>
      {tablet && !noProductsInProject && (
        <div className={classNames("tw-fixed tw-bottom-0 tw-z-[3] tw-w-full tw-transition-all tw-ease-out tw-duration-500", {
          "tw-max-h-0 tw-overflow-hidden": isVisible,
          "tw-max-h-fit": !isVisible
        })}>
          <SidePanel showRegionSelector={false} mobile={mobile} />
        </div>
      )}
    </>
  );
};
