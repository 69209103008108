import classNames from 'classnames';
import Button from '../../../../components/button';
import DropdownMenu from '../../../../components/dropdown';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../../../icons/saveIcon.svg';
import { ReactComponent as MoreIcon } from '../../../../icons/MoreIcon.svg';
import { ReactComponent as BlockIcon } from '../../../../icons/blockIcon.svg';
import { ReactComponent as PictureIcon } from '../../../../icons/pictureIcon.svg';
import { MenuItem, useMediaQuery } from '@mui/material';
import { ImageWithFallback } from '../../../../components/imageWithFallback';
import { useCallback } from 'react';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CheckIcon } from '../../../../icons/greenCheckIcon.svg';
import { useSelector } from 'react-redux';
import { isScreenshotsInProgress } from '../../../../store/selectors/organization';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SourceImageViewer } from '../SourceImageViewer';
import { DropdownModal } from '../../../../components/modals/DropdownModal';

export const SourceInfo = ({
  asTable,
  sourceImg,
  url,
  hostname,
  sourceHasScreenshot,
  onDeleteSource,
  onSaveSource,
  onBlockSource,
  title,
  product_id,
  dimensionsFrom,
}) => {
  const dialog = useDialog();
  const { id } = useParams();

  const showImageFullScreen = useCallback(() => {
    dialog.open(<SourceImageViewer project_id={id} product_id={product_id} url_source={url} />);
  }, [dialog, id, product_id, url]);

  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);
  const isMobile = useMediaQuery('(max-width: 425px)');

  const openMobileDropdown = useCallback(() => {
    dialog.open(
      <DropdownModal>
        <div className="tw-flex tw-flex-col tw-rounded-xl tw-items-center tw-divide-y tw-divide-y-[#F7F7F7]">
          <div className="tw-w-[90%]">
            <Button variant="text" onClick={onSaveSource}>
              Сохранить
            </Button>
          </div>
          <div className="tw-w-[90%]">
            <Button variant="text" onClick={onBlockSource} >
              Удалить и заблокировать
            </Button>
          </div>
        </div>
      </DropdownModal>
    );
  }, [dialog, onBlockSource, onSaveSource]);

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-gap-[10px] projectMobile:tw-relative">
      <div
        className={classNames('tw-flex tw-flex-row tw-items-start tw-gap-[14px] tw-w-full', {
          'tw-self-start': asTable,
        })}
      >
        {!asTable && (
          <ImageWithFallback
            srcImg={sourceImg}
            fallbackSrc={'/images/placeholders/other.png'}
            className="tw-rounded-[4px] tw-max-h-[70px] tw-min-h-[70px] tw-max-w-[70px] tw-min-w-[70px] tw-aspect-square projectMobile:tw-hidden"
            loading="lazy"
            alt="source_img"
          />
        )}
        <div className="tw-flex tw-flex-col tw-w-full tw-gap-1">
          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <a
              href={url || '#'}
              className="tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px] tw-cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              {hostname}
            </a>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 projectMobile:tw-absolute projectMobile:tw-right-0 projectMobile:tw-top-[-30px]">
              {/* <Button variant="text" className="hover:tw-bg-transparent tw-p-0 tw-w-[24px] tw-h-[24px]">
                <RestartIcon className="tw-fill-[#666666] tw-w-[24px] tw-h-[24px]" />
              </Button> */}
              {sourceHasScreenshot && (
                <Button
                  variant="text"
                  onClick={showImageFullScreen}
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
                >
                  <PictureIcon />
                </Button>
              )}
              <Button
                onClick={onDeleteSource}
                variant="text"
                disabled={screenshotsIsUpdating}
                className="hover:tw-bg-transparent tw-p-0 tw-w-[20px] tw-h-[20px]"
              >
                <DeleteIcon className="tw-fill-[#666666] tw-w-[20px] tw-h-[20px]" />
              </Button>
              {isMobile ? (
                <Button variant="text" className="tw-w-fit tw-p-0 hover:tw-bg-transparent" onClick={openMobileDropdown}>
                  <MoreIcon />
                </Button>
              ) : (
                <DropdownMenu
                  buttonChildren={
                    <>
                      <MoreIcon />
                    </>
                  }
                  btnProps={{
                    className: 'tw-p-0 tw-w-[fit-content] hover:tw-bg-transparent',
                    variant: 'text',
                    disabled: screenshotsIsUpdating,
                  }}
                >
                  {/* <MenuItem
                  className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                  onClick={handleCreateChatWithSupplier}
                  disableRipple
                >
                  <MailIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Написать поставщику
                </MenuItem> */}
                  <MenuItem
                    className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                    onClick={onSaveSource}
                    disableRipple
                  >
                    <SaveIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Сохранить
                  </MenuItem>
                  <MenuItem
                    className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                    onClick={onBlockSource}
                    disableRipple
                  >
                    <BlockIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Удалить и заблокировать
                  </MenuItem>
                </DropdownMenu>
              )}
            </div>
          </div>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-max-w-[487px] projectMobile:tw-hidden">
            {title}
          </p>
        </div>
      </div>
      {dimensionsFrom === 'catalogue' && (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 projectMobile:tw-hidden">
          <CheckIcon />
          <p className="tw-text-[#49CA4E] tw-font-medium tw-text-sm tw-leading-4">
            Применен справочник типовых размеров
          </p>
        </div>
      )}
    </div>
  );
};
